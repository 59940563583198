<template>
  <div class="useraddress">
    <!-- 取货地址 -->
    <div class="flex">
      <div class="titleinfo">联系人</div>
      <div><el-input placeholder="请输入内容"></el-input></div>
    </div>
    <div class="flex">
      <div class="titleinfo">联系电话</div>
      <div><el-input placeholder="发货人电话"></el-input></div>
    </div>
    <div class="flex">
      <div class="titleinfo">所在地区</div>
      <div
        @click="showPopup"
        :class="issslect ? 'citybox drepcolor' : ' citybox'"
      >
        {{ initCity }}
      </div>
    </div>
    <div class="flex lastbox">
      <div class="titleinfo">详细地址</div>
      <div style="flex:1">
        <el-input
          placeholder="请填写街道及门牌号"
          type="textarea"
          :rows="2"
          class="textareabox"
        ></el-input>
      </div>
    </div>
    <div class="flex flex-between  lastbox initaddress">
      <div class="titleinfo onlyaddress">设为默认地址</div>
      <!-- <div> <el-checkbox v-model="checked"></el-checkbox></div> -->
    </div>
    <div>
      <van-popup v-model="show" position="bottom">
        <van-area
          :area-list="areaList"
          :value="addrCode"
          @confirm="confirmF"
          cancel-button-text=" "
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
import "vant/lib/popup/style";
import "vant/lib/area/style";
import AreaList from "./area";

export default {
  data() {
    return {
      issslect: false,
      show: false,
      addrCode: "440105",
      areaList: AreaList,
      initCity: "请选择地区"
    };
  },

  methods: {
    confirmF(picker, value, index) {
      this.show = false;
      this.issslect = !this.issslect;
      this.initCity =
        picker[0].name + "" + picker[1].name + "" + picker[2].name;
    },
    showPopup() {
      this.show = true;
    }
  }
};
</script>
<style lang="scss" scoped="true">
::v-deep .van-picker__confirm {
  font-size: 32px;
}
::v-deep .van-picker__toolbar {
  height: 80px;
}
.textareabox {
  ::v-deep .el-textarea__inner {
    border: 0;
    color: #000000;
    font-size: 32px;
    color: #c0c4d6;
    padding: 0;
    height: 200px;
    resize: none;
    width: 100%;
    margin-left: 15px;
  }
}
.useraddress {
  height: 100vh;
  background: #f5f5f5;
  .citybox {
    margin-left: 15px;
    color: #c0c4d6;
  }
  .titleinfo {
    width: 150px;
    margin-right: 25px;
  }
  .onlyaddress {
    width: 300px;
  }
  .flex {
    font-size: 32px;
    background: #fff;
    padding: 30px 25px;
    border-bottom: 1px solid #efefef;
  }
  .initaddress {
    margin-top: 30px;
  }
  .lastbox {
    border-bottom: 0;
  }
}
.detailsbox {
  color: #cccccc;
  font-size: 28px;
}
.titlebox {
  font-size: 36px;
  margin-bottom: 19px;
}
.checkbox {
  margin: 90px auto 40px;
  background: #20c88b;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  position: relative;

  i {
    position: absolute;
    left: 50%;
    top: 20%;
    font-size: 86px;
    color: #fff;
    transform: translate(-50%);
  }
}
::v-deep .el-input__inner {
  border: 0;
  font-size: 32px;
}
.cerbox {
  padding: 0 40px;
  p {
    font-size: 28px;
  }
  cite {
    display: block;
    margin-bottom: 30px;
  }
  .usercenter {
    input {
      border: 0;
    }
  }
  .userbox {
    font-size: 32px;
    border-bottom: 1px solid #cccccc;
    i {
      font-style: normal;
    }
    b {
      margin-right: 40px;
      display: inline-block;
      padding: 30px 0;
      font-weight: normal;
      width: 150px;
    }
  }
  .imgboxlist {
    margin-bottom: 50px;
  }
  .idCard {
    width: 90%;
  }
  .photobox {
    width: 20%;
    position: absolute;
    left: 50%;
    top: 30%;
    transform: translate(-50%);
  }
  .tip {
    color: #b8b8b8;
    font-size: 24px;
    margin: 30px 0 50px 0;
  }
}
h1 {
  font-size: 40px;
  margin-bottom: 40px;
  font-weight: normal;
}
.btn_yellow {
  margin-top: 80px;
  background: #ffb606;
  color: #fff;
  border-radius: 44px;
  border: 0;
  width: 650px;
  height: 88px;
  font-size: 36px;
}
.drepcolor {
  color: #000000 !important;
}
</style>
