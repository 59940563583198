var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "useraddress" }, [
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "titleinfo" }, [_vm._v("联系人")]),
      _c("div", [_c("el-input", { attrs: { placeholder: "请输入内容" } })], 1)
    ]),
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "titleinfo" }, [_vm._v("联系电话")]),
      _c("div", [_c("el-input", { attrs: { placeholder: "发货人电话" } })], 1)
    ]),
    _c("div", { staticClass: "flex" }, [
      _c("div", { staticClass: "titleinfo" }, [_vm._v("所在地区")]),
      _c(
        "div",
        {
          class: _vm.issslect ? "citybox drepcolor" : " citybox",
          on: { click: _vm.showPopup }
        },
        [_vm._v(" " + _vm._s(_vm.initCity) + " ")]
      )
    ]),
    _c("div", { staticClass: "flex lastbox" }, [
      _c("div", { staticClass: "titleinfo" }, [_vm._v("详细地址")]),
      _c(
        "div",
        { staticStyle: { flex: "1" } },
        [
          _c("el-input", {
            staticClass: "textareabox",
            attrs: {
              placeholder: "请填写街道及门牌号",
              type: "textarea",
              rows: 2
            }
          })
        ],
        1
      )
    ]),
    _vm._m(0),
    _c(
      "div",
      [
        _c(
          "van-popup",
          {
            attrs: { position: "bottom" },
            model: {
              value: _vm.show,
              callback: function($$v) {
                _vm.show = $$v
              },
              expression: "show"
            }
          },
          [
            _c("van-area", {
              attrs: {
                "area-list": _vm.areaList,
                value: _vm.addrCode,
                "cancel-button-text": " "
              },
              on: { confirm: _vm.confirmF }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "flex flex-between  lastbox initaddress" },
      [
        _c("div", { staticClass: "titleinfo onlyaddress" }, [
          _vm._v("设为默认地址")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }